export const ADD_EMPLOYEE_REQUEST = 'ADD_EMPLOYEE_REQUEST';
export const ADD_EMPLOYEE_SUCCESS = 'ADD_EMPLOYEE_SUCCESS';
export const ADD_EMPLOYEE_FAIL = 'ADD_EMPLOYEE_FAIL';

export const SINGLE_USER_DETAILS_REQUEST = 'SINGLE_USER_DETAILS_REQUEST';
export const SINGLE_USER_DETAILS_SUCCESS = 'SINGLE_USER_DETAILS_SUCCESS';
export const SINGLE_USER_DETAILS_FAIL = 'SINGLE_USER_DETAILS_FAIL';

export const MY_EMPLOYEE_REQUEST = 'MY_EMPLOYEE_REQUEST';
export const MY_EMPLOYEE_SUCCESS = 'MY_EMPLOYEE_SUCCESS';
export const MY_EMPLOYEE_FAIL = 'MY_EMPLOYEE_FAIL';

export const GET_EMPLOYEE_REQUEST = 'GET_EMPLOYEE_REQUEST';
export const GET_EMPLOYEE_SUCCESS = 'GET_EMPLOYEE_SUCCESS';
export const GET_EMPLOYEE_FAIL = 'GET_EMPLOYEE_FAIL';

export const UPDATE_EMPLOYEE_REQUEST = 'UPDATE_EMPLOYEE_REQUEST';
export const UPDATE_EMPLOYEE_SUCCESS = 'UPDATE_EMPLOYEE_SUCCESS';
export const UPDATE_EMPLOYEE_FAIL = 'UPDATE_EMPLOYEE_FAIL';

export const ADD_ROLES_REQUEST = 'ADD_ROLES_REQUEST';
export const ADD_ROLES_SUCCESS = 'ADD_ROLES_SUCCESS';
export const ADD_ROLES_FAIL = 'ADD_ROLES_FAIL';
export const ADD_ROLES_RESET = 'ADD_ROLES_RESET';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
