export const GET_COMPANY_REQUEST = 'GET_COMPANY_REQUEST';
export const GET_COMPANY_SUCCESS = 'GET_COMPANY_SUCCESS';
export const GET_COMPANY_FAIL = 'GET_COMPANY_FAIL';

export const UPDATE_COMPANY_REQUEST = 'UPDATE_COMPANY_REQUEST';
export const UPDATE_COMPANY_SUCCESS = 'UPDATE_COMPANY_SUCCESS';
export const UPDATE_COMPANY_FAIL = 'UPDATE_COMPANY_FAIL';

export const UPDATE_COMPANY_ALLOWANCE_REQUEST = 'UPDATE_COMPANY_ALLOWANCE_REQUEST';
export const UPDATE_COMPANY_ALLOWANCE_SUCCESS = 'UPDATE_COMPANY_ALLOWANCE_SUCCESS';
export const UPDATE_COMPANY_ALLOWANCE_FAIL = 'UPDATE_COMPANY_ALLOWANCE_FAIL';