export const ADD_ATTENDENCE_REQUEST = 'ADD_ATTENDENCE_REQUEST';
export const ADD_ATTENDENCE_SUCCESS = 'ADD_ATTENDENCE_SUCCESS';
export const ADD_ATTENDENCE_FAIL = 'ADD_ATTENDENCE_FAIL';

export const GET_ATTENDENCE_REQUEST = 'GET_ATTENDENCE_REQUEST';
export const GET_ATTENDENCE_SUCCESS = 'GET_ATTENDENCE_SUCCESS';
export const GET_ATTENDENCE_FAIL = 'GET_ATTENDENCE_FAIL';

export const GET_SINGLE_ATTENDENCE_REQUEST = 'GET_SINGLE_ATTENDENCE_REQUEST';
export const GET_SINGLE_ATTENDENCE_SUCCESS = 'GET_SINGLE_ATTENDENCE_SUCCESS';
export const GET_SINGLE_ATTENDENCE_FAIL = 'GET_SINGLE_ATTENDENCE_FAIL';

export const OVERTIME_ATTENDENCE_REQUEST = 'OVERTIME_ATTENDENCE_REQUEST';
export const OVERTIME_ATTENDENCE_SUCCESS = 'OVERTIME_ATTENDENCE_SUCCESS';
export const OVERTIME_ATTENDENCE_FAIL = 'OVERTIME_ATTENDENCE_FAIL';

export const UPDATE_ALLOWANCE_REQUEST = 'UPDATE_ALLOWANCE_REQUEST';
export const UPDATE_ALLOWANCE_SUCCESS = 'UPDATE_ALLOWANCE_SUCCESS';
export const UPDATE_ALLOWANCE_FAIL = 'UPDATE_ALLOWANCE_FAIL';


export const UPDATE_OVERTIME_STATUS_REQUEST = 'UPDATE_OVERTIME_STATUS_REQUEST';
export const UPDATE_OVERTIME_STATUS_SUCCESS = 'UPDATE_OVERTIME_STATUS_SUCCESS';
export const UPDATE_OVERTIME_STATUS_FAIL = 'UPDATE_OVERTIME_STATUS_FAIL';